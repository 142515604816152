// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: AppFont;
  src: url("/assets/fonts/Inter-VariableFont_slnt,wght.ttf"); //change url accordingly
  }

/** Ionic CSS Variables **/
:root {
  /**
   * Palette Webmapp
   **/

  --ion-font-family: 'AppFont';

  --wm-color-primary: #2F9E44; // #2F9E44
  --wm-color-primary-rgb: 255, 126, 107;
  --wm-color-secondary: #FF8128; // #FF8128
  --wm-color-secondary-rgb: 80, 138, 168;
  --wm-color-tertiary: #93328E; // #93328E
  --wm-color-tertiary-rgb: 255, 177, 0;

  --wm-color-dark: #323031;
  --wm-color-dark-rgb: 50, 48, 49;
  --wm-color-dark-rgb-trans: 50, 48, 49, 0.8;
  --wm-color-grey: #D2D2D2;
  --wm-color-greybackground: #f2f2f2;
  --wm-color-light: #ffffff;
  --wm-color-light-rgb: 255, 255, 255;
  --wm-color-lightgray: #f5f3f6;
  --wm-color-lightgray-rgb: 245, 243, 246;
  --wm-color-darkgrey: #989898;
  --wm-color-darkgrey-rgb: 152, 152, 152;
  --wm-color-green: #50C467;
  --wm-color-red: #FF0000;

  --wm-color-track: #CA1551;
  --wm-color-trackborder: #FFF;

  --wm-color-skeleton: #dddddd;

  --wm-color-backdrop: #33333381;

  /** primary **/
  --ion-color-primary: var(--wm-color-primary); //#3880ff;
  --ion-color-primary-rgb: var(--wm-color-primary-rgb); //56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--wm-color-primary); //#3171e0;
  --ion-color-primary-tint: var(--wm-color-primary); //#4c8dff;

  /** secondary **/
  --ion-color-secondary: var(--wm-color-secondary); //#3dc2ff;
  --ion-color-secondary-rgb: var(--wm-color-secondary-rgb); //61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #FFEFCC; //#36abe0;
  --ion-color-secondary-tint: var(--wm-color-secondary); //#50c8ff;

  /** tertiary **/
  --ion-color-tertiary: var(--wm-color-tertiary); //#5260ff;
  --ion-color-tertiary-rgb: var(--wm-color-tertiary-rgb); //82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: var(--wm-color-tertiary); //#4854e0;
  --ion-color-tertiary-tint: var(--wm-color-tertiary); //#6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
}
