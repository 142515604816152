/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~ol/ol.css';
@import '~ol-ext/dist/ol-ext.min.css';
@import './assets/icons/webmapp-icons/style.css';

@import './theme/fonts.scss';
@import './theme/typography.scss';
@import './theme/variables.scss';

@import 'global_env';

@import url('https://fonts.googleapis.com/css?family=Montserrat:500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:500,700');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:500,700');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif:500,700');

@import url('https://fonts.googleapis.com/css?family=Inter:500,700');
@import url('https://fonts.googleapis.com/css?family=Merriweather:500,700');
@import url('https://fonts.googleapis.com/css?family=Lato:500,700');
@import url('https://fonts.googleapis.com/css?family=Manrope:500,700');
@import url('https://fonts.googleapis.com/css?family=Sora:500,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:500,700');

* {
  font-family: var(--wm-font-family-content, inherit);
}
.webmapp-icon {
  color: var(--wm-color-primary);
  padding: 0 5px;
  flex: 0 1 auto;
  path1,
  path2,
  path1::before,
  path2::before {
    color: var(--wm-color-primary);
    background: var(--wm-color-primary);
  }
}
/***** popover ****/
.popover-register {
  border-radius: 16px;

  &.popover-translucent.sc-ion-popover-ios-h .popover-arrow.sc-ion-popover-ios::after {
    background: var(--wm-color-primary);
  }

  webmapp-popover-register {
    background: var(--wm-color-primary);
    color: var(--wm-color-light);
  }
}

/*****************/

/***** register modal confirm ****/
.webmapp-modalconfirm-btn {
  color: var(--ion-color-dark) !important;
}

/*****************/

/********** back buttons ********/

.webmapp-backbutton {
  padding: 5px 20px;
  --icon-font-size: var(--wm-font-icon);
  &.webmapp-backbutton-chevron {
    font-size: var(--wm-font-icon);
  }

  &.webmapp-backbutton-dark {
    --color: var(--ion-color-dark) !important;
  }
}

/********************************/

/****** Filling for overlapping status bar ********/

.ios-filling {
  width: 100%;
  height: 24px;
}

/**************************************************/

/******** ripple effects  for buttons/icons ********/

.ripple-parent {
  position: relative;
  overflow: hidden;
}

/*****************************************************/

// .ios.platform-mobile ion-menu.menu-enabled, .ios.platform-mobile ion-menu.menu-enabled, .ios.platform-mobile ion-modal.show-page {
//   margin-top: constant(safe-area-inset-top); // for ios 11.1
//   margin-top: env(safe-area-inset-top); // for ios 11.2 and onwards
//   margin-bottom: env(safe-area-inset-bottom);
//   height: calc(100% - constant(safe-area-inset-top));
//   height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
// }
